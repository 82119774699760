import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';
import GetStarted from '../../../components/cta/GetStarted';
import Blockquote from '../../../components/frills/Blockquote';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H2 from '@rotaready/frecl/build/H2';
import H3 from '@rotaready/frecl/build/H3';
import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';
import Pill from '@rotaready/frecl/build/Pill';
import Button from '@rotaready/frecl/build/Button';
import ContactForm from '../../../components/forms/ContactForm';

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    display: block;
    position: relative;
  }
`;

const HeroImageContainer = styled.div`
  height: 450px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    height: 790px;
    width: 50%;
    margin-left: auto;
    transform: translate3d(0, -80px, 0);
  }
`;

const HeroContentContainer = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: calc(100% - 80px);
  }
`;

const Hero = styled.div`
  margin: 40px 0;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin: 0;
    text-align: left;
    width: 40%;
  }
`;

const LogoContainer = styled.div`
  margin: 40px 0;

  > div {
    margin: 0 auto;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    > div {
      margin: 0;
    }
  }
`;

const StatsBox = styled.div`
  padding: 0 20px;
  box-shadow: 0 0 8px 2px rgba(71, 91, 104, 0.1);
  background-color: ${({ theme: { colors } }) => colors.grey10};
  border: 1px solid ${({ theme: { colors } }) => colors.grey20};
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  transform: translateY(-160px);
  flex-wrap: wrap;
`;

const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 200px;
  height: 80px;
  padding: 20px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 40px 0;
  }
`;

const StatHeadline = styled(H2).attrs({
  uistyle: 'brand160',
})``;

const StatSubtitle = styled(H4).attrs({
  uistyle: 'grey',
})``;

const BodyWrapper = styled.div`
  max-width: 640px;
  margin: -60px auto 0 auto;
`;

const TileTagContainer = styled.div`
  margin-bottom: 40px;

  > div {
    display: inline-block;
    margin-right: 10px;
  }
`;

const TileTag = styled(Pill).attrs({
  size: 'sm',
  uistyle: 'primary',
})``;

const Section = styled.div`
  margin-bottom: 60px;
`;

const Content = styled.div`
  margin-bottom: 30px;
`;

const TextBlock = styled(Text).attrs({
  size: 'lg',
})``;

const PopQuote = styled(H4).attrs({
  uistyle: 'primary',
  fontWeight: 600,
})`
  text-align: center;
`;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  invertColors: false,
};

class Page extends React.Component {
  render() {
    const {
      data,
    } = this.props;

    return (
      <Body header={header}>
        <SEO
          title="Riding House Cafes Customer Story - Rotaready"
          description="Since implementing Rotaready, Riding House Cafes have been able to reduce labour costs by 2% and achieve an ROI of 698%"
          url="customer-stories/hospitality-riding-house-cafes"
        />

        <HeroContainer>
          <HeroImageContainer>
            <GatsbyImage
              image={data.heroBackground.childImageSharp.gatsbyImageData}
              style={{ height: '100%' }}
              alt="Vibey restaurant interior of Riding House Cafe"
            />
          </HeroImageContainer>

          <HeroContentContainer>
            <ResponsiveContainer>
              <Hero>
                <TileTagContainer>
                  <TileTag text="Hospitality" />
                </TileTagContainer>

                <H3 uistyle="primary" text="Customer story" fontWeight={600} />

                <LogoContainer>
                  <GatsbyImage
                    image={data.logo.childImageSharp.gatsbyImageData}
                    style={{ height: '50px' }}
                    alt="Riding House Cafes logo"
                  />
                </LogoContainer>

                <H3 uistyle="brand160" text="Since implementing Rotaready, Riding House Cafes have been able to reduce labour costs by 2% and achieve an ROI of 698%" />
              </Hero>
            </ResponsiveContainer>
          </HeroContentContainer>
        </HeroContainer>

        <ResponsiveContainer>
          <StatsBox>
            <StatItem>
              <StatHeadline text="3" />
              <StatSubtitle text="sites" />
            </StatItem>

            <StatItem>
              <StatHeadline text="150" />
              <StatSubtitle text="employees" />
            </StatItem>

            <StatItem>
              <StatHeadline text="2%" />
              <StatSubtitle text="drop in labour costs" />
            </StatItem>

            <StatItem>
              <StatHeadline text="698%" />
              <StatSubtitle text="ROI" />
            </StatItem>
          </StatsBox>
        </ResponsiveContainer>

        <ResponsiveContainer>
          <BodyWrapper>
            <Section>
              <Content>
                <H3 uistyle="brand160" text="Riding House Cafés" />
              </Content>

              <Content>
                <TextBlock text="Learn how London-based restaurant group Riding House Cafés addressed issues such as staffing variations between breakfast and dinner services and ultimately achieved lower labour costs and reduced admin time with the implementation of Rotaready." />
              </Content>

              <Content>
                <H3 uistyle="brand160" text="Seeking Simplicity and Efficiency" />
              </Content>

              <Content>
                <TextBlock text="Riding House Cafés deliver a fun and friendly neighbourly atmosphere offering a range of classic dishes and weekend brunches across their three trendy London venues." />
              </Content>

              <Content>
                <TextBlock text="With varying staffing levels needed to cover lunch and dinner service across three busy sites and an objective to balance labour costs, Operations Director Neil Buckingham tells us about making the move away from clunky systems. The team needed a solution that worked reliably and was easy to use for all users, from head office to site staff." />
              </Content>

              <Content>
                <Blockquote
                  text="The decision to switch to Rotaready stemmed from the need for a more user-friendly system that could effectively streamline our employee scheduling processes."
                  attribution="Neil Buckingham, Operations Director"
                />
              </Content>

              <Content>
                <H3 uistyle="brand160" text="Why Rotaready?" />
              </Content>

              <Content>
                <TextBlock text="The decision to switch to Rotaready was fuelled by the need for a more user-friendly solution with a wider scope of management tools. The team's previous systems lacked simplicity, with clunky processes that hindered operational efficiency." />
              </Content>

              <Content>
                <Blockquote
                  text="We needed a system that would help us make more informed decisions to control labour costs, with the flexibility to manage our specific staff scheduling needs. Rotaready's trustworthy sales process and comprehensive features, including staffing profiles and cost control, played a crucial role in the decision-making process."
                  attribution="Neil Buckingham, Operations Director"
                />
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline1.childImageSharp.gatsbyImageData}
                alt="Beautifully presented open burger with an oozing poached egg placed on top"
              />
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Navigating the transition to Rotaready" />
              </Content>

              <Content>
                <TextBlock text="Implementing Rotaready has marked a pivotal moment for the restaurant group as they move towards their goal of streamlining employee processes and reducing costs." />
              </Content>

              <Content>
                <TextBlock text="The implementation process itself was straightforward, with support and guidance from the Rotaready team to customise and configure the software." />
              </Content>

              <Content>
                <TextBlock text="Since the implementation, the group has benefitted from a wealth of innovative features that have become indispensable tools for Neil and his team." />
              </Content>
            </Section>

            <Section>
              <Content>
                <H4 uistyle="primary" text="1. Cost control" />
              </Content>

              <Content>
                <TextBlock text="One of the standout features for Riding House Café is Rotaready's robust cost control functionality, namely aggregated totals, which gives the restaurant group full visibility of costs across all sites. And enables the team to closely monitor their labour costs in real-time, providing insights into actual vs projected spending." />
              </Content>

              <Content>
                <Blockquote
                  text="With the level of visibility we now have with Rotaready, it's easier to see midweek how the week is going and be able to flex staffing levels as and when you need to."
                  attribution="Neil Buckingham, Operations Director"
                />
              </Content>
            </Section>

            <Section>
              <Content>
                <H4 uistyle="primary" text="2. Staffing profiles" />
              </Content>

              <Content>
                <TextBlock text="The software's staffing profile feature offers real-time insights into staffing levels throughout the day, enabling the team to visualise staff levels and fluctuations. The group can then proactively adjust schedules to align with demand, optimise resource allocation and ensure optimal staffing levels across all service periods." />
              </Content>

              <Content>
                <Blockquote
                  text="Rotaready's staffing profile feature helps us make sure the staffing level fluctuates throughout the day appropriately to cover the busy periods as well as the quieter times in between, helping us maintain a balance between labour costs and service requirements."
                  attribution="Neil Buckingham, Operations Director"
                />
              </Content>
            </Section>

            <Section>
              <Content>
                <H4 uistyle="primary" text="3. Tronc module" />
              </Content>

              <Content>
                <TextBlock text="As time has gone on, Rotaready has developed to include more features and functionalities that support the day-to-day operations of the group, such as the tronc module which is now used daily to handle tronc calculations and distributions." />
              </Content>

              <Content>
                <Blockquote
                  text="The Rotaready team is great at listening to customer feedback, it was really good to be part of the early development of the tronc module, so we felt like it was built with our operational needs in mind."
                  attribution="Neil Buckingham, Operations Director"
                />
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline2.childImageSharp.gatsbyImageData}
                alt="Warm interior of Rail House Cafe in Victoria, London"
              />
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Impact" />
              </Content>

              <Content>
                <TextBlock text="The implementation of Rotaready has yielded excellent results for the restaurant group, supporting their objectives to improve labour management and increase operational efficiency while balancing costs. Including:" />
              </Content>
            </Section>

            <Section>
              <Content>
                <H4 uistyle="primary" text="Optimised staffing levels & wage costs" />
              </Content>

              <Content>
                <TextBlock text="By utilising Rotaready's cost control functionality and staffing profiles to carefully monitor spend and demand across the business, Riding House Cafés has been able to successfully optimise its staffing levels. As a result, the restaurant group has been able reduce labour spend by 2%, despite wage increases" />
              </Content>
            </Section>

            <Section>
              <Content>
                <H4 uistyle="primary" text="Reduction in admin time" />
              </Content>

              <Content>
                <TextBlock text="By streamlining employee scheduling and centralising admin tasks within the Rotaready platform, the team has been able to make a significant reduction in administrative time. Staff self-service features also enable team members to take control of their schedules, easily manage their availability, request time off and swap shifts – reducing time spent on these tasks by management." />
              </Content>
            </Section>

            <Section>
              <Content>
                <H4 uistyle="primary" text="Significant ROI" />
              </Content>

              <Content>
                <TextBlock text="The group has been able to record a tangible cost saving to the business since the implementation of Rotaready, achieving an ROI of 698%. They have also been able to better deploy over 37 hours a week." />
              </Content>

              <Content>
                <Blockquote
                  text="Rotaready has been instrumental in helping us balance lower labour costs while maintaining consistent service quality, resulting in a 2% drop in labour costs, despite wages inevitably increasing."
                  attribution="Neil Buckingham, Operations Director"
                />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Building for the future" />
              </Content>

              <Content>
                <TextBlock text="Beyond the initial implementation, the Riding House Cafés team continues to collaborate with the Rotaready team in adopting new developments and feeding back to ensure the platform continues to meet the evolving needs of the group." />
              </Content>

              <Content>
                <TextBlock text="Outside of the impressive cost and time savings, the platform has become an integral part of daily operations for team members across the business." />
              </Content>
            </Section>

            <Section>
              <Content>
                <Blockquote
                  text="The ability for our team to autonomously manage their holiday bookings, adjust personal details, swap shifts and request time off brings immense flexibility. Equally important is the management-level control which enables us to easily manage permission levels and gives us great visibility of rotas and costs across sites. Rotaready really delivers a seamless experience for both employees and management users!"
                  attribution="Neil Buckingham, Operations Director"
                />
              </Content>
            </Section>

            <Section>
              <ContactForm
                title="Download the story"
                submitLabel="Download PDF"
                source="customerstories_ridinghousecafes"
                label="rotaready.com/customer-stories/hospitality-riding-house-cafes"
                checkboxes={[
                  {
                    id: 'marketing',
                    text: 'I\'d like to receive updates, offers and new content from Rotaready by email. You can unsubscribe at any time.',
                    required: false,
                    initial: false,
                  },
                ]}
                onSuccess={(
                  <>
                    <TextBlock>
                      <H3 uistyle="brand160" text="Happy reading 📚" />
                    </TextBlock>

                    <TextBlock>
                      <Text size="lg" text="Thank you for downloading this customer story! Click below to get the file." />
                    </TextBlock>

                    <TextBlock>
                      <a href="/files-static/customer-stories/Rotaready-Story-Riding-House-Cafes.pdf" download>
                        <Button uistyle="primary" text="Download PDF" size="md" />
                      </a>
                    </TextBlock>
                  </>
                )}
              />
            </Section>
          </BodyWrapper>
        </ResponsiveContainer>

        <GetStarted />
      </Body>
    );
  }
}

export default Page;

export const query = graphql`
  query {
    heroBackground: file(relativePath: { eq: "customer-stories/riding-house-cafes/header.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 50
          transformOptions: { cropFocus: CENTER, fit: COVER }
        )
      }
    }

    logo: file(relativePath: { eq: "customer-stories/riding-house-cafes/logo.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 60, quality: 100)
      }
    }

    inline1: file(relativePath: { eq: "customer-stories/riding-house-cafes/inline1.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }

    inline2: file(relativePath: { eq: "customer-stories/riding-house-cafes/inline2.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }
  }
`;
